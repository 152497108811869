import React from "react";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { BiPhoneCall } from "react-icons/bi";
import Fade from "react-reveal/Fade";

import "./style.scss";

const CallToAction = () => {
  const handlePhone = () => {
    window.open("tel:+13122135113", "_self");
  };

  const d = new Date();
  let month = d.getMonth();

  return (
    <>
      {/* <Fade bottom cascade> */}
      <div className="call_to_action">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h2>We're here to answer your questions:</h2>
          </div>
          <div className="col-md-6 d-flex flex-wrap text-center justify-content-center">
            <div className="in-btn">
              <a
                href={`https://calendly.com/peterealestate`}
                target={"_blank"}
                style={{ whiteSpace: "nowrap" }}
              >
                <FaRegCalendarAlt /> Schedule a Call
              </a>
            </div>
            <div className="in-btn">
              <a
                href="javascript:;"
                style={{ whiteSpace: "nowrap" }}
                onClick={handlePhone}
              >
                <BiPhoneCall /> Call/Text Us
              </a>
            </div>
            <div className="in-btn">
              <a
                href="mailto:ir@alamoequity.com"
                style={{ whiteSpace: "nowrap" }}
              >
                <FiMail /> Email Us
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* </Fade> */}
    </>
  );
};

export default CallToAction;
